import { useTheme } from '@emotion/react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

// Our Components
import { PrimaryButton } from 'components/Button/Button';
import Loader from 'components/Loader';

// Our Queries
import useMutateExecuteLoan from 'hooks/useMutateExecuteLoan';
import useGetProfile from 'hooks/useGetProfile';

// Our Constants
const SUPPORTED_OFFER_TYPES = ['new_loan', 'cash_out', 'refinance'];

function OfferButton({
	isLoading,
	isRoleLoading,
	profileData,
	handleLoanExecution,
	modalCallBack
}) {
	if (isLoading || isRoleLoading)
		return <Loader boxSX={{ width: 162, justifyContent: 'center' }} />;

	const { role } = profileData;

	if (role === 'CLIENT')
		return (
			<PrimaryButton onClick={() => handleLoanExecution()}>
				Execute Loan
			</PrimaryButton>
		);

	return (
		<PrimaryButton onClick={() => modalCallBack(true)}>
			Contact Client
		</PrimaryButton>
	);
}

function OffersColumnItem({
	lenderLogoUrl,
	offerType,
	offerId,
	modalCallBack
}) {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;

	const { mutate: executeLoan, isLoading } = useMutateExecuteLoan();

	const handleLoanExecution = () => {
		executeLoan(offerId);
	};

	const { isLoading: isRoleLoading, data: profileData } = useGetProfile();

	const height = offerType === 'cash_out' ? 820 : 900;

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				height,
				zIndex: 2
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					border: `2px solid ${primary.soraBlue}`,
					borderRadius: '10px',
					alignItems: 'center',
					padding: 6,
					paddingTop: 4.5,
					height: 900
				}}
			>
				<Box
					sx={{
						height: 80,
						width: 140,
						display: 'flex',
						alignItems: 'center',
						marginBottom: 3
					}}
				>
					<Box
						component="img"
						src={lenderLogoUrl}
						sx={{ maxHeight: 80, maxWidth: 140 }}
					/>
				</Box>
				<OfferButton
					isLoading={isLoading}
					isRoleLoading={isRoleLoading}
					profileData={profileData}
					handleLoanExecution={handleLoanExecution}
					modalCallBack={modalCallBack}
				/>
			</Box>
		</Box>
	);
}

OffersColumnItem.propTypes = {
	offerType: PropTypes.oneOf(SUPPORTED_OFFER_TYPES).isRequired,
	offerId: PropTypes.number.isRequired,
	modalCallBack: PropTypes.func
};

OffersColumnItem.defaultProps = {
	modalCallBack: () => true
};

export default OffersColumnItem;
