import { Box, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

// Our Component
import CardItem from 'components/SoraWallet/Cards/CardItem';

// Our Utility
import { dataIsValid, dollarFormatter } from 'shared/utils';
import sentenceCapitalize from 'shared/utils/formatting/sentenceCapitalize';

// Icons
import autoLoan from 'assets/images/liability/auto_loan.svg';
import houseLoan from 'assets/images/liability/house_loan.svg';
import personalLoan from 'assets/images/liability/personal_loan.svg';
import studentLoan from 'assets/images/liability/student_loans.svg';
import PersonalLoan from 'assets/icons/wallet_icons/PersonalLoan.svg';

// Constant
import { MORTGAGE } from 'shared/constants';

const loanInformation = {
	MORTGAGE: {
		currentLoanHeading: 'Home Loan',
		currentLoanIcon: houseLoan
	},
	HELOC: {
		currentLoanHeading: 'Home Loan (HELOC)',
		currentLoanIcon: houseLoan
	},
	AUTO: {
		currentLoanHeading: 'Auto Loan',
		currentLoanIcon: autoLoan
	},
	STUDENT: {
		currentLoanHeading: 'Student Loan',
		currentLoanIcon: studentLoan
	},
	PERSONAL: {
		currentLoanHeading: 'Personal Loan',
		currentLoanIcon: personalLoan
	}
};

function LoanTypeAndCostOfDebtCard({
	currentLoanDetails,
	homeDetails,
	lender
}) {
	const SoraTheme = useTheme();

	const { primary } = SoraTheme.palette;

	const { costOfDebt, tradeLineType } = currentLoanDetails;

	const getLoanInfoBasedOnTradeline = loanInformation[tradeLineType];

	const { currentLoanIcon, currentLoanHeading } = getLoanInfoBasedOnTradeline;

	const isMortgageTradeline = tradeLineType === MORTGAGE;

	const hasHomeDetails = dataIsValid(homeDetails);

	const homeEstimatedValue =
		hasHomeDetails && homeDetails.estimatedValue
			? dollarFormatter.format(homeDetails.estimatedValue)
			: 'NA';

	const isHomeAddressValid =
		dataIsValid(homeDetails) && dataIsValid(homeDetails.addressLine1);

	const homeAddressLine1 = isHomeAddressValid
		? sentenceCapitalize(currentLoanDetails.home.addressLine1)
		: 'NA';

	const homeAddressLine2 = isHomeAddressValid
		? `${sentenceCapitalize(currentLoanDetails.home.city)}, ${
				currentLoanDetails.home.state
		  } ${currentLoanDetails.home.zipcode}`
		: 'NA';

	return (
		<CardItem
			withoutButton
			buttonSx={{ alignSelf: 'center' }}
			sx={{
				background:
					'linear-gradient(180deg, rgba(38, 133, 193, 0.3) 0%, rgba(0, 156, 255, 0.051) 100%);'
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-evenly',
					marginBottom: 3,
					minHeight: 74
				}}
			>
				<Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
					{currentLoanHeading}
				</Typography>

				{isMortgageTradeline && (
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							minHeight: 42
						}}
					>
						<Typography variant="body2">
							{homeAddressLine1}
						</Typography>

						<Typography variant="body2">
							{homeAddressLine2}
						</Typography>
					</Box>
				)}

				{lender && <Typography variant="body1">{lender}</Typography>}
			</Box>
			{currentLoanDetails.logoUrl && (
				<Box
					component="img"
					src={currentLoanDetails.logoUrl}
					sx={{
						userDrag: 'none',
						userSelect: 'none',
						WebkitUserSelect: 'none',
						WebKitUserDrag: 'none'
					}}
				/>
			)}

			<Box sx={{ display: 'flex' }}>
				{isMortgageTradeline ? (
					<Typography variant="subtitle1">Home Value</Typography>
				) : (
					<Typography variant="subtitle1">
						Total Cost of Debt
					</Typography>
				)}
			</Box>

			<Typography variant="h4" sx={{ color: primary.indigo }}>
				{isMortgageTradeline && homeEstimatedValue}

				{!isMortgageTradeline && dollarFormatter.format(costOfDebt)}
			</Typography>

			<Box
				component="img"
				src={
					currentLoanHeading === 'Personal Loan'
						? PersonalLoan
						: currentLoanIcon
				}
				alt={currentLoanHeading}
				sx={{
					display: { xs: 'none', md: 'block' },
					position: 'absolute',
					right: '1rem',
					bottom: 0,
					width: '5.5rem !important',
					height: ' 5.5rem !important',
					marginBottom: '0.375rem !important'
				}}
			/>
		</CardItem>
	);
}

export default LoanTypeAndCostOfDebtCard;
