import { createTheme, responsiveFontSizes } from '@mui/material/styles';

// Fonts
import GascongneLight from 'assets/fonts/Gascogne/GascogneTSLight2.woff2';
import LydiaReg from 'assets/fonts/Lydia/LydiaSansRegular.woff2';
import LydiaSemiBold from 'assets/fonts/Lydia/LydiaSansSemiBold.woff2';
import LydiaBold from 'assets/fonts/Lydia/LydiaSansBold.woff2';

// Create the Sora theme
const SoraTheme = createTheme({
	palette: {
		mode: 'light',
		primary: {
			black: '#000000',
			contrastText: '#ffffff',
			darkGrey: '#454746',
			disabled: 'rgba(0, 0, 0, 0.38)',
			disabledGrey: '#C7C7C7',
			grey: '#989898',
			highEmphasis: 'rgba(0, 0, 0, 0.87)',
			iceBlue: '#C8E9F1DE',
			indigo: '#295983',
			lightBlue: '#97D8E8',
			lightGrey: '#dbdbdb',
			lightShade: '#ECECEC',
			main: '#2685C1',
			mediumEmphasis: 'rgba(0, 0, 0, 0.6)',
			mintGreen: '#45AE95',
			orange: '#FF8300',
			pink: '#FFB29E',
			red: '#B00020',
			soraBlue: '#2685C1',
			soraMariner: '#246cb3',
			white: '#ffffff',
			yellow: '#FFBC00'
		},
		secondary: {
			main: '#97D8E8',
			contrastText: '#000000'
		},
		warning: {
			main: 'rgb(255 188 0 / 32%)', // Subject to change
			mainHover: 'rgb(255 188 0 / 16%)' // Subject to change
		},
		error: {
			main: '#B00020'
		},
		success: {
			main: '#00a152'
		},
		gradient: {
			gradient1: ' linear-gradient(180deg, #B0CDE4 -26.77%, #FFF 100%);',
			soraBlueToWhiteLinearGradient:
				'linear-gradient(180deg, #206EA7 0%, rgba(38, 133, 193, 0.21) 100%);',
			onboardingNavLinearGradient:
				'linear-gradient(180deg, rgba(182, 219, 238, 0.2) 0%, rgba(182, 219, 238, 0) 0.01%, #B6DBEE 111.23%);',
			prospectClientLinearGradient:
				'linear-gradient(180deg, #ABD2E8 0%, #E8F5FF 100%)',
			prospectClientLandingLinearGradient:
				'linear-gradient(180deg, #EBF3F9 0%, #FFF 100%), #FFF'
		},
		carouselGradient: {
			gradient1: 'linear-gradient(180deg, #ABD2E8 0%, #E8F5FF 100%)',
			gradient2:
				'linear-gradient(180deg, #295983 0%, rgba(41, 89, 131, 0.42) 100%)',
			gradient3: 'linear-gradient(180deg, #C1E2ED 0%, #EBFBFF 100%)',
			gradient4: 'linear-gradient(180deg, #ABD2E8 0%, #E8F5FF 100%)'
		},
		chart: {
			mortgage: '#97D8E8',
			auto: '#FFBC00',
			student: '#2685C1',
			personal: '#FFB29E',
			creditcard: '#295983',
			heloc: '#FF8300'
		},
		analytics: {
			interestSavingsCurrentLoan: '#44AD95',
			interestSavingsBestOffer: '#2684C1',
			netPresentValueExisting: '#2684C1',
			netPresentValueNewLiability: '#295983',
			netPresentValueRefi: '#FFBC00',
			upsidePotentialFV: '#FF8300',
			upsidePotentialContribution: '#2684C1'
		},
		resultsChart: {
			investmentaccounts: '#44AD95',
			property: '#A8C5DA',
			brokerage: '#005E4D',
			nonBrokerage: '#98CA90',
			mortgage: '#93DEED',
			heloc: '#FF8300',
			sbloc: '#776CBF',
			auto: '#FFBC00',
			personal: '#FFB29E',
			student: '#2684C1',
			creditcard: '#295983',
			other: '#979797',
			house: '#49929D'
		},
		dtsColors: {
			na: '#FFBC00',
			veryLow: '#DF340C',
			low: '#FF8300',
			medium: '#93DEED',
			high: '#2685C1',
			veryHigh: '#45AE95'
		}
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: `
				@font-face {
					font-family: 'Lydia';
					font-style: normal;
					font-display: swap;
					font-weight: 400;
					src: local('Lydia'),
					local('Lydia-Regular'),
					url(${LydiaReg}) format('woff2');
					unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
					U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
				};
				@font-face {
					font-family: 'Lydia-Semi-Bold';
					font-style: normal;
					font-display: swap;
					font-weight: 400;
					src: local('Lydia-Semi-Bold'), local('Lydia-Semi-Bold'), url(${LydiaSemiBold}) format('woff2');
					unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
					U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
				};
				@font-face {
					font-family: 'Lydia-Bold';
					font-style: normal;
					font-display: swap;
					font-weight: 400;
					src: local('Lydia-Bold'), local('Lydia-Bold'), url(${LydiaBold}) format('woff2');
					unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
					U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
				};
				@font-face {
					font-family: 'Gascogne';
					font-style: normal;
					font-display: swap;
					font-weight: 400;
					src: local('Gascongne'), local('Gascongne'), url(${GascongneLight}) format('woff2');
					unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
					U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
				};
			`
		},
		MuiDataGrid: {
			styleOverrides: {
				root: {
					'& .MuiDataGrid-iconButtonContainer > button': {
						padding: 0
					}
				}
			}
		}
	},
	typography: {
		fontFamily: "'Lydia', 'Helvetica', 'Arial', sans-serif",
		fontWeightMedium: 600,
		h1: {
			fontWeight: 700,
			fontSize: '6rem',
			lineHeight: 1,
			letterSpacing: '-0.094rem'
		},
		h2: {
			fontWeight: 700,
			fontSize: '3.75rem',
			lineHeight: 1
			// no definition for letterSpacing: ''
		},
		h3: {
			fontWeight: 700,
			fontSize: '3rem',
			lineHeight: 1
			// no definition for letterSpacing: ''
		},
		h4: {
			fontWeight: 700,
			fontSize: '1.7rem',
			lineHeight: 1
			// no definition for letterSpacing: ''
		},
		h5: {
			fontWeight: 700,
			fontSize: '1.2rem',
			lineHeight: 1,
			letterSpacing: '0.0112rem'
		},
		h6: {
			fontWeight: 700,
			fontSize: '1rem',
			lineHeight: '1.5rem',
			letterSpacing: '0.02rem'
		},
		subtitle1: {
			fontWeight: 700,
			fontSize: '.9rem',
			lineHeight: '1.4rem',
			letterSpacing: '0.02rem'
		},
		subtitle2: {
			fontWeight: 700,
			fontSize: '0.8rem',
			lineHeight: '1.3rem',
			letterSpacing: '0.02rem'
		},
		body1: {
			fontWeight: 400,
			fontSize: '0.9rem',
			lineHeight: '1.4rem',
			letterSpacing: '0rem'
		},
		body2: {
			fontWeight: 400,
			fontSize: '0.8rem',
			lineHeight: '1.3rem',
			letterSpacing: '0rem'
		},
		button: {
			fontWeight: 700,
			fontSize: '0.8rem',
			lineHeight: '1rem',
			letterSpacing: '0.02rem'
		},
		caption: {
			fontWeight: 400,
			fontSize: '0.75rem',
			lineHeight: '1.2rem'
		},
		overline: {
			fontSize: '0.65rem',
			fontWeight: 700,
			lineHeight: '0.9rem',
			wordWrap: 'break-word',
			textTransform: 'none'
		},
		footerText: {
			fontWeight: 200,
			fontSize: '0.625rem',
			lineHeight: '1rem'
		},
		h1Gascogne: {
			fontFamily: 'Gascogne',
			fontSize: '2.2rem',
			fontWeight: 100,
			lineHeight: '2.3rem',
			fontFeatureSettings: "'clig': off, 'liga': off",
			color: '#295983'
		},
		h2Gascogne: {
			fontFamily: 'Gascogne',
			fontSize: '1.6rem',
			fontWeight: 100,
			lineHeight: '2rem',
			wordWrap: 'break-word',
			fontFeatureSettings: "'clig': off, 'liga': off"
		},
		h3Gascogne: {
			fontFamily: 'Gascogne',
			fontSize: '1.25rem',
			fontWeight: 100,
			lineHeight: '1.5rem',
			fontFeatureSettings: "'clig': off, 'liga': off"
		},
		subtitleGascongne: {
			color: 'black',
			fontSize: 20,
			fontFamily: 'Gascogne',
			fontWeight: '100',
			wordWrap: 'break-word'
		}
	}
});

const SoraThemeResponsiveFontSizes = responsiveFontSizes(SoraTheme);

export default SoraThemeResponsiveFontSizes;
