import { Box, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

// Our Component
import CardItem from 'components/SoraWallet/Cards/CardItem';

// Our Utility
import { dollarFormatter } from 'shared/utils';

// Our Assets
import WalletGraph from 'assets/icons/wallet_icons/WalletGraph.svg';

function BalanceAndMonthyAmountCard({ currentLoanDetails }) {
	const cardImage = WalletGraph;
	const SoraTheme = useTheme();

	const { primary } = SoraTheme.palette;

	const { monthlyPayment, totalOutstandingBalance: outstandingBalance } =
		currentLoanDetails;

	const formattedOutstandingBalance =
		dollarFormatter.format(outstandingBalance);
	const formattedMonthlyAmount = dollarFormatter.format(monthlyPayment);
	return (
		<CardItem
			withoutButton
			buttonSx={{ alignSelf: 'center' }}
			sx={{
				background: 'linear-gradient(180deg, #295983 0%, #91B0CB 100%)'
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-evenly',
					marginBottom: 3,
					minHeight: 74
				}}
			>
				<Typography
					variant="subtitle1"
					sx={{ marginBottom: 1, color: primary.white }}
				>
					Balance Outstanding
				</Typography>

				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						minHeight: 42
					}}
				>
					<Typography variant="h4" sx={{ color: primary.white }}>
						{formattedOutstandingBalance}
					</Typography>
				</Box>
			</Box>

			<Box sx={{ display: 'flex' }}>
				<Typography variant="subtitle1" sx={{ color: primary.white }}>
					Monthly Payment
				</Typography>
			</Box>

			<Typography variant="h4" sx={{ color: primary.white }}>
				{formattedMonthlyAmount}
			</Typography>
			<Box
				component="img"
				src={cardImage}
				sx={{
					display: { xs: 'none', md: 'block' },
					position: 'absolute',
					right: '1rem',
					bottom: 0,
					width: '5.5rem !important',
					height: ' 5.5rem !important',
					marginBottom: '0.375rem !important'
				}}
			/>
		</CardItem>
	);
}

export default BalanceAndMonthyAmountCard;
