import { HELOC } from 'shared/constants';

const LOAN_INQUIRY_TYPES = [
	'Automotive Loan (New)',
	'Automotive Loan (Refinance)',
	'Cash Out Refinance',
	'Construction Loan',
	'Farm Loan',
	HELOC,
	'Lot Loan',
	'Mortgage Loan (New)',
	'Mortgage Loan (Refinance)',
	'Personal Loan (New)',
	'Personal Loan (Refinance)',
	'Reverse Mortgage',
	'Commercial Loan',
	'Student Loan (Refinance)',
	'Other'
];

export const OTHER_OFFER_TYPE = 'OTHER';
export const OTHER_TRADELINE_TYPE = 'OTHERS';

export default LOAN_INQUIRY_TYPES;
