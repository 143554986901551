import { Grid, Typography } from '@mui/material';
import { useState, useMemo } from 'react';
import { useQueryClient } from 'react-query';

// Our Components
import { PrimaryButton } from 'components/Button/Button';
import CurrencyTextInput from 'components/Input/CurrencyTextInput';
import GetFullAddressPartialForm from 'components/Forms/addressRelated/GetFullAddressPartialForm';
import Loader from 'components/Loader';
import LoanInquirySuccessModal from 'components/Modal/LoanInquiryConfirmationModal';
import TextInput from 'components/Input/TextInput';
import SoraTextField from 'components/Input/SoraTextField';
import RequiredFieldsTooltip from 'components/Forms/NewLoanForms/RequiredFieldsTooltip';

// Our hooks 🪝
import useMutateCreateDeal from 'hooks/hubspot/useMutateCreateDeal';
import useMutateSaveNewLoanInquiry from 'hooks/newLoanInquiries/useMutateSaveNewLoanInquiry';
import useMutateUpdateLoanInquiry from 'hooks/newLoanInquiries/useMutateUpdatedLoanInquiry';
import useMutateSendGenericEmail from 'hooks/emails/useMutateSendGenericEmail';

// Our utils
import { clearFormValues, isSubmissionReady } from 'shared/utils';
import createDefaultCloseDate from 'shared/utils/hubspot/createDefaultCloseDate';
import formatCollection from 'shared/utils/formatting/currency/formatCollection';

// Query Keys
import { ERROR_MESSAGE_DATA } from 'shared/query-keys';

// Our Constants
import { NUMBER } from 'components/Input/Types';
import {
	OTHER_OFFER_TYPE,
	OTHER_TRADELINE_TYPE
} from 'shared/constants/LoanInquiry/LoanInquiryTypes';

function OtherLoanForm({
	isClientNameProvided,
	advisorEmail,
	advisorName,
	advisorCompany,
	advisorWebsite,
	additionalNotesValue,
	addressLine1Value,
	addressLine2Value,
	cityValue,
	clientId,
	creditScoreValue,
	disableAutoComplete,
	firstName,
	lastName,
	loanAmountValue,
	loanRequestId,
	loanType,
	setDisableAutoComplete,
	stateValue,
	zipCodeValue
}) {
	const queryClient = useQueryClient();
	const currentURL = window.location.href;
	const atLoanRequestDetailsUrl = currentURL.includes('loan-request-details');

	// API Calls
	const saveLoanInquiry = useMutateSaveNewLoanInquiry();
	const createDeal = useMutateCreateDeal();
	const sendGenericEmail = useMutateSendGenericEmail();
	const updateLoanRequest = useMutateUpdateLoanInquiry();
	const { isLoading: savingLoanInquiry } = saveLoanInquiry;

	// Build full name
	const fullNameValue = `${firstName} ${lastName}`;

	// Pre-populated state items
	const [creditScore, setCreditScore] = useState(creditScoreValue || '');
	const [loanAmount, setLoanAmount] = useState(loanAmountValue || '');

	// Address related fields
	const [addressLine1, setAddressLine1] = useState(addressLine1Value || '');
	const [addressLine2, setAddressLine2] = useState(addressLine2Value || '');
	const [city, setCity] = useState(cityValue || '');
	const [state, setState] = useState(stateValue || '');
	const [zipCode, setZipCode] = useState(zipCodeValue || '');

	// Other fields
	const [additionalNotes, setAdditionalNotes] = useState(
		additionalNotesValue || ''
	);

	// Modal
	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

	// Required Fields for Other Loan Form
	const loanRequestDetailsRequiredFields = [loanAmount];

	const isFormReady = useMemo(
		() =>
			isSubmissionReady(loanRequestDetailsRequiredFields) &&
			isClientNameProvided,
		loanRequestDetailsRequiredFields
	);

	const submitNewLoanInquiry = () => {
		const newLoanInquiryData = {
			additionalNotes,
			addressLine1,
			addressLine2,
			city,
			clientId,
			creditScore,
			firstName,
			lastName,
			loanAmount,
			offerType: OTHER_OFFER_TYPE,
			tradelineType: OTHER_TRADELINE_TYPE,
			state,
			zipCode
		};

		// Call mutation to update user's data
		saveLoanInquiry.mutate(newLoanInquiryData, {
			onSuccess: () => {
				setIsSuccessModalOpen(true);
			},
			onError: () => {
				// Set error message
				queryClient.setQueryData(
					ERROR_MESSAGE_DATA,
					'There was an error making your loan request.'
				);
			}
		});
	};

	const sendFormData = () => {
		const sendToArray = ['lending@sorafinance.com'];

		const subject = 'Other Loan Request';

		const message = `<pre>
			The advisor has priced a loan for one of their clients 
			
			Advisor making request:
			Advisor's name: ${advisorName}
			Advisor's email: ${advisorEmail}
			
			They made a request for the following CLIENT:

			Client's name: ${fullNameValue}
			Requested loan amount: ${loanAmount}
			Client's Address: ${addressLine1} ${addressLine2} ${city}, ${state} ${zipCode}
			Client's Credit score: ${creditScore}

			Additional Notes:

			${additionalNotes}</pre>
		`;

		sendGenericEmail.mutate(
			{ firstName, lastName, subject, message, sendToArray },
			{
				onSuccess: () => {
					clearFormValues([
						setAdditionalNotes,
						setAddressLine1,
						setAddressLine2,
						setCity,
						setCreditScore,
						setLoanAmount,
						setState,
						setZipCode
					]);
				}
			}
		);
	};

	const submitForm = (event) => {
		event.preventDefault();

		const closeDate = createDefaultCloseDate();
		const valuesToFormat = [
			{
				type: 'currency',
				value: loanAmount,
				label: 'Desired loan amount:'
			}
		];

		const formattedCollection = formatCollection(valuesToFormat);
		const advisorNotes = `Advisor's name: ${advisorName}
				Advisor's email: ${advisorEmail}
				Advisor's company: ${advisorCompany}
				Credit Score: ${creditScore}
				Address: ${addressLine1} ${addressLine2} ${city}, ${state} ${zipCode}
				${formattedCollection}
				State: ${state}
				------------------------------------------------------------------------------------------------
				Advisor's notes: ${additionalNotes}`;

		createDeal.mutate({
			advisorInfo: {
				email: advisorEmail,
				companyName: advisorCompany,
				companyDomain: advisorWebsite
			},
			properties: {
				amount: loanAmount,
				dealname: `${advisorName} / ${fullNameValue}`,
				dealtype: loanType,
				borrower: fullNameValue,
				closedate: closeDate
			},
			noteInfo: {
				hs_note_body: advisorNotes
			}
		});

		// If we're at /loan-inquiry, perform the following
		if (!atLoanRequestDetailsUrl) {
			submitNewLoanInquiry();
			sendFormData();
		}
		// Else if we're at /loan-inquiry-details, perform the following
		else if (atLoanRequestDetailsUrl) {
			// Call new mutations here patching the new loan inquiry
			updateLoanRequest(loanRequestId);
		}
	};

	return (
		<Grid container spacing={2}>
			{savingLoanInquiry && <Loader />}

			{!atLoanRequestDetailsUrl && (
				<Grid item xs={12}>
					<Typography
						variant="h2Gascogne"
						gutterBottom
						component="div"
						sx={{
							marginTop: 6
						}}
					>
						Other Loan
					</Typography>

					<Typography
						variant="body2"
						gutterBottom
						component="div"
						sx={{
							marginBottom: 4
						}}
					>
						Fill in the information below to submit a loan request
						to Sora. You will be able to complete and review your
						loan request in the dashboard task bar.
					</Typography>
				</Grid>
			)}

			{!savingLoanInquiry && (
				<>
					{/* Modal shown on successful submission of  */}
					<LoanInquirySuccessModal isOpen={isSuccessModalOpen} />

					<Grid item xs={12} md={6}>
						<TextInput
							label="Credit Score"
							value={creditScore}
							onChange={setCreditScore}
							type={NUMBER}
							inputProps={{
								'data-test': 'creditScore'
							}}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<CurrencyTextInput
							label="Loan Amount *"
							value={loanAmount}
							onChange={setLoanAmount}
							type={NUMBER}
							inputProps={{
								'data-test': 'loanAmount'
							}}
						/>
					</Grid>
					<GetFullAddressPartialForm
						label={
							atLoanRequestDetailsUrl ? 'Address *' : 'Address'
						}
						addressLine1={addressLine1}
						addressLine2={addressLine2}
						city={city}
						state={state}
						zipCode={zipCode}
						setAddressLine1={setAddressLine1}
						setAddressLine2={setAddressLine2}
						setCity={setCity}
						setState={setState}
						setZipCode={setZipCode}
						disableAutoComplete={disableAutoComplete}
						setDisableAutoComplete={setDisableAutoComplete}
					/>
					<Grid item xs={12}>
						<SoraTextField
							label="Additional Notes"
							value={additionalNotes}
							onChange={setAdditionalNotes}
						/>
					</Grid>
					<Grid item xs={12} marginTop={4} marginBottom={6}>
						<RequiredFieldsTooltip>
							<span>
								<PrimaryButton
									disabled={!isFormReady}
									onClick={submitForm}
								>
									Submit
								</PrimaryButton>
							</span>
						</RequiredFieldsTooltip>
					</Grid>
				</>
			)}
		</Grid>
	);
}

export default OtherLoanForm;
