import { Box, Paper, Skeleton, Typography } from '@mui/material/index';

// Our Components
import { TextButton } from 'components/Button/Button';

// Our Prop Types
import CommonProps from 'shared/prop-types';

// Local Constants
const SoraMark =
	'https://prod-public-asset.s3.amazonaws.com/SoraLogos/Sora_Mark.png';

function InsightsCard({ currentInsightQuery, sx, skeletonSx, onClick }) {
	const {
		isFetching: isInsightFetching,
		isSuccess: isInsightSuccess,
		data
	} = currentInsightQuery;

	const isInsightReady = !isInsightFetching;

	if (isInsightFetching)
		return (
			<Skeleton
				variant="rounded"
				sx={{
					marginRight: 2,
					height: 250,
					borderRadius: 6,
					overflow: 'hidden',
					...skeletonSx
				}}
			/>
		);

	if (isInsightSuccess) {
		const { insightType, insightTextOutput } = data;

		const textHasColon = insightTextOutput.includes(':');

		const insightTextWithoutPrefix = textHasColon
			? insightTextOutput.slice(insightTextOutput.indexOf(':') + 1)
			: insightTextOutput;

		return (
			<Paper
				elevation={3}
				sx={{
					padding: 4,
					paddingTop: 1,
					paddingBottom: 1,
					marginLeft: 1,
					marginRight: 2,
					marginTop: 1,
					marginBottom: 1,
					borderRadius: 6,
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-evenly',
					minHeight: 250,
					background:
						'linear-gradient(180deg, #FFF 0%, #F5F8FA 100%)',
					'&:hover': {
						cursor: 'pointer'
					},
					...sx
				}}
				onClick={() => {
					if (isInsightReady) onClick(data);
				}}
			>
				<Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 2 }}>
					<Box
						component="img"
						src={SoraMark}
						sx={{ height: '2.5rem' }}
					/>
					<Typography
						variant="h3Gascogne"
						sx={{ fontSize: '1.5rem' }}
					>
						{insightType}
					</Typography>
				</Box>

				<Typography
					variant="body2"
					sx={{
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						display: '-webkit-box',
						WebkitBoxOrient: 'vertical',
						wordWrap: 'break-word',
						WebkitLineClamp: 4
					}}
				>
					{insightTextWithoutPrefix}
				</Typography>

				{isInsightReady && (
					<TextButton onClick={() => onClick(data)}>
						Request more information
					</TextButton>
				)}
			</Paper>
		);
	}
}

InsightsCard.propTypes = {
	currentInsightQuery: CommonProps.reactQueryPropType.isRequired,
	skeletonSx: CommonProps.sx
};

InsightsCard.defaultProps = {
	skeletonSx: {}
};

export default InsightsCard;
