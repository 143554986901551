/* eslint-disable consistent-return */
import { useEffect, useState, useMemo } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { useQueryClient } from 'react-query';
import { isURL } from 'validator';

// Our components
import Alert from 'components/Alert';
import CurrencyTextInput from 'components/Input/CurrencyTextInput';
import { PrimaryButton } from 'components/Button/Button';
import Dropdown from 'components/Dropdown/Dropdown';
import GetFullAddressPartialForm from 'components/Forms/addressRelated/GetFullAddressPartialForm';
import Input from 'components/Input/TextInput';
import Loader from 'components/Loader';
import LoanInquirySuccessModal from 'components/Modal/LoanInquiryConfirmationModal';
import SoraTextField from 'components/Input/SoraTextField';
import { NUMBER, TEXT } from 'components/Input/Types';

// Our hooks 🪝
import useMutateCreateDeal from 'hooks/hubspot/useMutateCreateDeal';
import useMutateSaveNewLoanInquiry from 'hooks/newLoanInquiries/useMutateSaveNewLoanInquiry';
import useMutateUpdateLoanInquiry from 'hooks/newLoanInquiries/useMutateUpdatedLoanInquiry';
import useMutateSendGenericEmail from 'hooks/emails/useMutateSendGenericEmail';

// Our Query keys
import { ERROR_MESSAGE_DATA } from 'shared/query-keys';

// Utils
import { clearFormValues } from 'shared/utils';
import createDefaultCloseDate from 'shared/utils/hubspot/createDefaultCloseDate';
import formatCollection from 'shared/utils/formatting/currency/formatCollection';

// Constants
import { BUSINESS, NEW_LOAN_OFFER_TYPE } from 'shared/constants';

function SmallBusinessLoanForm({
	isClientNameProvided,
	advisorEmail,
	advisorName,
	advisorCompany,
	advisorWebsite,
	additionalNotesValue,
	addressLine1Value,
	addressLine2Value,
	cityValue,
	clientId,
	companyNameValue,
	companyTypeValue,
	companyWebsiteValue,
	creditScoreValue,
	disableAutoComplete,
	emailAddressValue,
	firstName,
	income2YearsAgoValue,
	incomeLastYearValue,
	lastName,
	loanAmountValue,
	loanRequestId,
	loanTypeValue,
	postSuccessEvent,
	reasonForLoanValue,
	revenue2YearsAgoValue,
	revenueLastYearValue,
	setDisableAutoComplete,
	stateValue,
	zipCodeValue,
	loanType
}) {
	const queryClient = useQueryClient();
	const updateLoanInquiry = useMutateUpdateLoanInquiry();

	// API Calls
	const saveLoanInquiry = useMutateSaveNewLoanInquiry();
	const { isLoading: savingLoanInquiry } = saveLoanInquiry;

	// Mutations
	const sendGenericEmail = useMutateSendGenericEmail();
	const createDeal = useMutateCreateDeal();

	// Build full name
	const fullNameValue = `${firstName} ${lastName}`;

	// Pre-populated state items
	const [reasonForLoan, setReasonForLoan] = useState(
		reasonForLoanValue || ''
	);
	const [loanAmount, setLoanAmount] = useState(loanAmountValue || '');
	const [companyName, setCompanyName] = useState(companyNameValue || '');
	const [companyType, setCompanyType] = useState(companyTypeValue || '');
	const [companyWebsite, setCompanyWebsite] = useState(
		companyWebsiteValue || ''
	);

	const [selectedLoanType, setSelectedLoanType] = useState(
		loanTypeValue || ''
	);

	// Address related fields
	const [addressLine1, setAddressLine1] = useState(addressLine1Value || '');
	const [addressLine2, setAddressLine2] = useState(addressLine2Value || '');
	const [city, setCity] = useState(cityValue || '');
	const [state, setState] = useState(stateValue || '');
	const [zipCode, setZipCode] = useState(zipCodeValue || '');

	const [revenueLastYear, setRevenueLastYear] = useState(
		revenueLastYearValue || ''
	);
	const [incomeLastYear, setIncomeLastYear] = useState(
		incomeLastYearValue || ''
	);
	const [revenue2YearsAgo, setRevenue2YearsAgo] = useState(
		revenue2YearsAgoValue || ''
	);
	const [income2YearsAgo, setIncome2YearsAgo] = useState(
		income2YearsAgoValue || ''
	);
	const [creditScore, setCreditScore] = useState(creditScoreValue || '');

	const [emailAddress, setEmailAddress] = useState(emailAddressValue || '');
	const [subject, setSubject] = useState('Commercial Loan Request');
	const [message, setMessage] = useState('');
	const [additionalNotes, setAdditionalNotes] = useState(
		additionalNotesValue || ''
	);
	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

	const isFormReady = useMemo(
		() => isClientNameProvided,
		[isClientNameProvided]
	);

	useEffect(
		() =>
			setMessage(`<pre>
			The advisor has request a commercial loan for one of their clients 
			
			Advisor making request:
			Advisor's name: ${advisorName}
			Advisor's email: ${advisorEmail}
			
			They made a request for commercial loan for the following CLIENT:

			Client's name: ${fullNameValue}
			Client's Email address: ${emailAddress}
			Reason for loan: ${reasonForLoan}
			Requested loan amount: ${loanAmount}
			Company name: ${companyName}
			Company Type: ${companyType}
			Company website: ${companyWebsite}
			Requested Loan Type: ${selectedLoanType}
			Revenue last year: ${revenueLastYear}
			Income last year: ${incomeLastYear}

			Revenue 2 years ago: ${revenue2YearsAgo}
			Income 2 years ago: ${income2YearsAgo}
			Credit score of guarantor(s): ${creditScore}
			Company HQ location: ${addressLine1} ${addressLine2} ${city}, ${state} ${zipCode}

			Additional Notes:

			${additionalNotes}</pre>
		`),
		[
			emailAddress,
			reasonForLoan,
			loanAmount,
			companyName,
			companyType,
			companyWebsite,
			revenueLastYear,
			incomeLastYear,
			revenue2YearsAgo,
			income2YearsAgo,
			creditScore,
			addressLine1,
			addressLine2,
			city,
			state,
			zipCode,
			additionalNotes,
			selectedLoanType
		]
	);

	const sendFormData = () => {
		const sendToArray = ['lending@sorafinance.com'];

		sendGenericEmail.mutate(
			{ firstName, lastName, subject, message, sendToArray },
			{
				onSuccess: () => {
					clearFormValues([
						setAdditionalNotes,
						setAddressLine1,
						setAddressLine2,
						setCity,
						setCompanyName,
						setCompanyType,
						setCompanyWebsite,
						setCreditScore,
						setEmailAddress,
						setIncome2YearsAgo,
						setIncomeLastYear,
						setLoanAmount,
						setSelectedLoanType,
						setReasonForLoan,
						setRevenue2YearsAgo,
						setRevenueLastYear,
						setState,
						setSubject,
						setZipCode
					]);
				}
			}
		);
	};

	const submitNewLoanInquiry = () => {
		const newLoanInquiryData = {
			additionalNotes,
			addressLine1,
			addressLine2,
			city,
			clientId,
			companyName,
			companyType,
			companyWebsite,
			creditScore,
			emailAddress,
			firstName,
			income2YearsAgo,
			incomeLastYear,
			lastName,
			loanAmount,
			selectedLoanType,
			offerType: NEW_LOAN_OFFER_TYPE,
			reasonForLoan,
			revenue2YearsAgo,
			revenueLastYear,
			state,
			tradelineType: BUSINESS,
			zipCode
		};

		// Call mutation to update user's data
		saveLoanInquiry.mutate(newLoanInquiryData, {
			onSuccess: () => {
				setIsSuccessModalOpen(true);
			},
			onError: () => {
				// Set error message
				queryClient.setQueryData(
					ERROR_MESSAGE_DATA,
					'There was an error making your loan request.'
				);
			}
		});
	};

	const updateLoanRequest = () => {
		const updatedLoanInquiryData = {
			additionalNotes,
			addressLine1,
			addressLine2,
			city,
			clientId,
			companyName,
			companyType,
			companyWebsite,
			creditScore,
			emailAddress,
			firstName,
			income2YearsAgo,
			incomeLastYear,
			lastName,
			loanAmount,
			selectedLoanType,
			offerType: NEW_LOAN_OFFER_TYPE,
			reasonForLoan,
			revenue2YearsAgo,
			revenueLastYear,
			state,
			tradelineType: BUSINESS,
			zipCode
		};

		// Call mutation to update user's data
		updateLoanInquiry.mutate(
			{ loanRequestId, updatedLoanInquiryData },
			{
				onSuccess: () => {
					clearFormValues([
						setAdditionalNotes,
						setAddressLine1,
						setAddressLine2,
						setCity,
						setCompanyName,
						setCompanyType,
						setCompanyWebsite,
						setCreditScore,
						setEmailAddress,
						setIncome2YearsAgo,
						setIncomeLastYear,
						setLoanAmount,
						setSelectedLoanType,
						setReasonForLoan,
						setRevenue2YearsAgo,
						setRevenueLastYear,
						setState,
						setSubject,
						setZipCode
					]);

					if (postSuccessEvent) {
						postSuccessEvent();
					}
				},
				onError: () => {
					// Set error message
					queryClient.setQueryData(
						ERROR_MESSAGE_DATA,
						'There was an error making your loan request.'
					);
				}
			}
		);
	};

	const { isLoading } = sendFormData;

	const isValidWebsite = useMemo(
		() => isURL(companyWebsite),
		[companyWebsite]
	);

	const getCompanyWebsiteErrorMessage = useMemo(() => {
		if (companyWebsite === '') return 'Company website cannot be empty';
		if (!isValidWebsite) return 'Company website is not valid';
	}, [companyWebsite]);

	const currentURL = window.location.href;
	const atLoanRequestDetailsUrl = currentURL.includes('loan-request-details');

	const submitForm = (event) => {
		event.preventDefault();

		const closeDate = createDefaultCloseDate();
		const valuesToFormat = [
			{
				type: 'currency',
				value: loanAmount,
				label: 'Desired loan amount:'
			},
			{
				type: 'currency',
				value: revenueLastYear,
				label: 'Revenue from 2023'
			},
			{
				type: 'currency',
				value: incomeLastYear,
				label: 'Income from 2023'
			},
			{
				type: 'currency',
				value: revenue2YearsAgo,
				label: 'Revenue from 2022'
			},
			{
				type: 'currency',
				value: income2YearsAgo,
				label: 'Income from 2022'
			}
		];

		const formattedCollection = formatCollection(valuesToFormat);
		const advisorNotes = `Advisor's name: ${advisorName}
				Advisor's email: ${advisorEmail}
				Advisor's company: ${advisorCompany}
				Credit Score: ${creditScore}
				Reason for loan: ${reasonForLoan}
				${formattedCollection}
				Company Name: ${companyName}
				Company Type: ${companyType}
				Company Website: ${companyWebsite}
				Highest credit score of guarantor(s): ${creditScore}
				Loan Type: ${selectedLoanType}
				Company headquarters location: ${addressLine1} ${addressLine2} ${city}, ${state} ${zipCode}
				------------------------------------------------------------------------------------------------
				Advisor's notes: \n${additionalNotes}`;

		createDeal.mutate({
			advisorInfo: {
				email: advisorEmail,
				companyName: advisorCompany,
				companyDomain: advisorWebsite
			},
			properties: {
				amount: loanAmount,
				dealname: `${advisorName} / ${fullNameValue}`,
				dealtype: loanType,
				borrower: fullNameValue,
				closedate: closeDate
			},
			noteInfo: {
				hs_note_body: advisorNotes
			}
		});

		// If we're at /loan-inquiry, perform the following
		if (!atLoanRequestDetailsUrl) {
			submitNewLoanInquiry();
			sendFormData();
		}
		// Else if we're at /loan-inquiry-details, perform the following
		else if (atLoanRequestDetailsUrl) {
			// Call new mutations here patching the new loan inquiry
			updateLoanRequest(loanRequestId);
		}
	};

	// Handlers for mutation
	const { isError: sendFormDataError } = sendFormData;

	return (
		<Box
			component="form"
			noValidate
			autoComplete="off"
			sx={{ height: '100%', width: '100%' }}
		>
			{!atLoanRequestDetailsUrl && (
				<Grid item xs={12}>
					<Typography
						variant="h2Gascogne"
						gutterBottom
						component="div"
						sx={{
							marginTop: 6
						}}
					>
						Commercial Loan
					</Typography>

					<Typography
						variant="body2"
						gutterBottom
						component="div"
						sx={{
							marginBottom: 4
						}}
					>
						Fill in the information below to submit a loan request
						to Sora. You will be able to complete and review your
						loan request in the dashboard task bar.
					</Typography>
				</Grid>
			)}

			{isLoading || (savingLoanInquiry && <Loader />)}
			{!isLoading && !savingLoanInquiry && (
				<>
					{sendFormDataError && <Alert variant="error" />}

					{/* Modal shown on successful submission of  */}
					<LoanInquirySuccessModal isOpen={isSuccessModalOpen} />
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							{/* Reason for loan */}
							<Input
								id="reasonForLoan"
								label="Reason for loan"
								type={TEXT}
								name="Reason for loan"
								onChange={setReasonForLoan}
								value={reasonForLoan}
								endAdornment="hidden"
								inputProps={{
									'data-test': 'reasonForLoan'
								}}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							{/* Desired Loan Amount */}
							<CurrencyTextInput
								label="Desired loan amount"
								value={loanAmount}
								onChange={setLoanAmount}
								type={NUMBER}
								inputProps={{
									'data-test': 'loanAmount'
								}}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							{/* Company name */}
							<Input
								id="companyName"
								label="Company name"
								type={TEXT}
								name="Company name"
								onChange={setCompanyName}
								value={companyName}
								endAdornment="hidden"
								inputProps={{
									'data-test': 'companyName'
								}}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<Dropdown
								items={['LLC', 'S-corp', 'C-corp', 'Other']}
								selected={companyType}
								onChange={setCompanyType}
								variant="outlined"
								label="Company type"
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<Input
								id="companyWebsite"
								label="Company Website"
								type={TEXT}
								onChange={setCompanyWebsite}
								value={companyWebsite}
								error={!isValidWebsite}
								helperText={getCompanyWebsiteErrorMessage}
								placeholder="companywebsite.com"
								inputProps={{
									'data-test': 'companyWebsite'
								}}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							{/* Last years revenue */}
							<CurrencyTextInput
								label={`Revenue from ${
									new Date().getFullYear() - 1
								}`}
								subLabel="Estimate is fine"
								value={revenueLastYear}
								onChange={setRevenueLastYear}
								type={NUMBER}
								inputProps={{
									'data-test': 'revenueLastYear'
								}}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							{/* Last years income */}
							<CurrencyTextInput
								label={`Income from ${
									new Date().getFullYear() - 1
								}`}
								subLabel="Estimate is fine"
								value={incomeLastYear}
								onChange={setIncomeLastYear}
								type={NUMBER}
								inputProps={{
									'data-test': 'incomeLastYear'
								}}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							{/* Revenue 2 years ago */}
							<CurrencyTextInput
								label={`Revenue from ${
									new Date().getFullYear() - 2
								}`}
								subLabel="Estimate is fine"
								value={revenue2YearsAgo}
								onChange={setRevenue2YearsAgo}
								type={NUMBER}
								inputProps={{
									'data-test': 'revenue2YearsAgo'
								}}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							{/* Net income 2 years ago */}
							<CurrencyTextInput
								label={`Income from ${
									new Date().getFullYear() - 2
								}`}
								subLabel="Estimate is fine"
								value={income2YearsAgo}
								onChange={setIncome2YearsAgo}
								type={NUMBER}
								inputProps={{
									'data-test': 'income2YearsAgo'
								}}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							{/* Credit Score */}
							<Input
								label="Highest credit score of guarantor(s)"
								value={creditScore}
								onChange={setCreditScore}
								type={NUMBER}
								inputProps={{
									'data-test': 'creditScore'
								}}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							{/* Loan Type */}
							<Dropdown
								items={[
									'SBA Loans',
									'Revolving Line of Credit',
									'Commercial Real Estate'
								]}
								selected={selectedLoanType}
								onChange={setSelectedLoanType}
								variant="outlined"
								label="Loan Type"
							/>
						</Grid>

						<GetFullAddressPartialForm
							label="Company headquarters location"
							addressLine1={addressLine1}
							addressLine2={addressLine2}
							city={city}
							state={state}
							zipCode={zipCode}
							setAddressLine1={setAddressLine1}
							setAddressLine2={setAddressLine2}
							setCity={setCity}
							setState={setState}
							setZipCode={setZipCode}
							disableAutoComplete={disableAutoComplete}
							setDisableAutoComplete={setDisableAutoComplete}
						/>
						<Grid item xs={12}>
							<SoraTextField
								label="Additional Notes"
								value={additionalNotes}
								onChange={setAdditionalNotes}
							/>
						</Grid>
					</Grid>
				</>
			)}

			<Grid item xs={12} marginTop={4} marginBottom={6}>
				<PrimaryButton disabled={!isFormReady} onClick={submitForm}>
					Submit
				</PrimaryButton>
			</Grid>
		</Box>
	);
}

export default SmallBusinessLoanForm;
